import React, { ReactNode, useEffect, useState } from "react";
import CookieConsent, {
  resetCookieConsentValue,
  getCookieConsentValue,
} from "react-cookie-consent";
import { MdOutlineCookie } from "react-icons/md";

import { Button, ButtonShape } from "../forms";
import { AppName, loadAnalyticsProviders } from "../../utils/consent";
import { googleTag } from "../../utils/analytics";

const cookieName = "timber-cookie-consent";

type Props = {
  appName: AppName;
};

export const CookieNotice: React.FC<Props> = ({ appName }) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [consentState, setConsentState] = useState<"granted" | "denied" | null>(
    null
  );

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    setConsentState(
      getConsentStateFromCookieValue(getCookieConsentValue(cookieName)) as
        | "granted"
        | "denied"
    );
  }, []);

  useEffect(() => {
    if (consentState) {
      googleTag("consent", "update", {
        ad_storage: consentState,
        analytics_storage: consentState,
        ad_personalization: consentState,
        ad_user_data: consentState,
      });

      if (consentState === "granted") {
        loadAnalyticsProviders(appName);
      }
    }
  }, [consentState]);

  const handleAccept = () => {
    setConsentState("granted");
  };

  const handleDecline = () => {
    setConsentState("denied");
  };

  const handleReset = () => {
    resetCookieConsentValue(cookieName);
    setConsentState(null);
  };

  if (!isBrowser) {
    return null;
  }

  return (
    <>
      {consentState && <CookieSettingsButton onClick={handleReset} />}
      <CookieConsent
        visible={consentState ? "hidden" : "show"}
        cookieName={cookieName}
        location="bottom"
        disableStyles
        enableDeclineButton
        buttonText="Nõustun"
        declineButtonText="Keeldun"
        flipButtons
        containerClasses="container bg-white fixed sm:mb-8 sm:pl-14 pl-10 py-8 pr-10 sm:flex items-center justify-center shadow-lg rounded-2xl"
        contentClasses="mb-4 lg:mb-0 sm:mr-4 text-lg"
        buttonWrapperClasses="flex flex-col gap-2"
        declineButtonClasses="decline"
        ButtonComponent={NoticeButton}
        onAccept={handleAccept}
        onDecline={handleDecline}
        overlay
        overlayClasses="fixed bg-black/40 inset-0 z-20 flex justify-center"
      >
        See veebisait kasutab küpsiseid analüüsi ja turunduse eesmärkidel.
        Nõustudes annate loa küpsiste kasutamiseks.{" "}
        <a
          target="_blank"
          href="https://timber.ee/kasutustingimused"
          className="text-green underline"
          rel="noreferrer"
        >
          Loe lähemalt
        </a>
      </CookieConsent>
    </>
  );
};

const NoticeButton = ({
  children,
  className,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick(): void;
}) => {
  const isDeclineButton = className?.includes("decline");
  return (
    <Button
      className="px-16"
      filled={!isDeclineButton}
      onClick={onClick}
      shape={ButtonShape.ROUND}
    >
      {children}
    </Button>
  );
};

const CookieSettingsButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="fixed bottom-2 left-2">
      <Button filled shape={ButtonShape.CIRCLE} onClick={onClick}>
        <MdOutlineCookie />
      </Button>
    </div>
  );
};

const getConsentStateFromCookieValue = (value: string | undefined) => {
  if (value === "true") {
    return "granted";
  }

  if (value === "false") {
    return "denied";
  }

  return null;
};
