import React from "react";

type Link = {
  title: string;
  url: string;
  children?: Link[];
};

export const LinksList: React.FC<{ links: Link[] }> = ({ links }) => (
  <div>
    <ul className="timber-list">
      {links.map(link => (
        <li key={link.url} className="mb-2">
          <a
            href={link.url}
            target="_blank"
            rel="noreferrer"
            className="text-light-green hover:text-green"
          >
            {link.title}
          </a>
          {link.children && (
            <ul className="my-1">
              {link.children.map(child => (
                <li key={child.url} className="mb-2">
                  <a
                    href={child.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-light-green hover:text-green"
                  >
                    {child.title}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  </div>
);
