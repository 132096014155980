exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-liitu-tsx": () => import("./../../../src/pages/liitu.tsx" /* webpackChunkName: "component---src-pages-liitu-tsx" */),
  "component---src-pages-sertifitseerimine-tsx": () => import("./../../../src/pages/sertifitseerimine.tsx" /* webpackChunkName: "component---src-pages-sertifitseerimine-tsx" */),
  "component---src-pages-teenused-tsx": () => import("./../../../src/pages/teenused.tsx" /* webpackChunkName: "component---src-pages-teenused-tsx" */),
  "component---src-pages-toetused-tsx": () => import("./../../../src/pages/toetused.tsx" /* webpackChunkName: "component---src-pages-toetused-tsx" */),
  "component---src-templates-toetus-tsx": () => import("./../../../src/templates/toetus.tsx" /* webpackChunkName: "component---src-templates-toetus-tsx" */)
}

