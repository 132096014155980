import React, { ReactChild } from "react";
import classnames from "classnames";
import { getBackgroundImageStyle } from "../../utils";

interface Props {
  content: ReactChild;
  illustration: ReactChild;
  flip?: boolean;
  className?: string;
  backgroundImage?: string;
  backgroundImageClassName?: string;
  id?: string;
}

export const SectionLayout: React.FC<Props> = ({
  content,
  illustration,
  flip,
  className,
  backgroundImage,
  backgroundImageClassName,
  id,
}) => {
  const illustrationElement = illustration ? (
    <div
      className={classnames(
        "col-span-12 lg:col-span-7 flex items-center",
        flip ? "" : "lg:pl-10"
      )}
    >
      {illustration}
    </div>
  ) : null;

  return (
    <div className="relative overflow-hidden py-6 lg:py-12 w-full" id={id}>
      {backgroundImage && (
        <div
          style={getBackgroundImageStyle(backgroundImage)}
          className={classnames(
            "absolute h-full w-full bg-no-repeat transform",
            backgroundImageClassName
          )}
        ></div>
      )}
      <section className={classnames("container relative", className)}>
        {illustrationElement ? (
          <div className="inline-grid grid-cols-12 relative w-full">
            {flip && illustrationElement}
            <div
              className={classnames(
                "col-span-12 flex items-center lg:col-span-5 lg:pr-4"
              )}
            >
              {content}
            </div>
            {!flip && illustrationElement}
          </div>
        ) : (
          <div className="text-lg lg:px-40 mt-12 leading-8">{content}</div>
        )}
      </section>
    </div>
  );
};
